<template>
    <div class="order">
        <img src="https://khome2.tuzuu.com/vita/chome_3.png">

        <div class="order-1">
            <img src="https://khome2.tuzuu.com/vita/home_17.png" style="margin-bottom: 20px"/>
            <div class="home-desc">
                <div class="title"> 轻松二步，让健康简单起来</div>
                <HomeDesc/>
            </div>
        </div>

        <div class="order-2">
            <div class="title">用户反馈</div>

            <div class="my-swipe">
                <van-swipe :autoplay="3000" indicator-color="white">
                    <van-swipe-item>
                        <div class="my-swipe-item">
                            <div class="flex" style="margin-bottom:25px">
                                <van-image round width="50px" height="50px" src="https://khome2.tuzuu.com/vita/preson_1.png"/>
                                <div class="flex-column-center person" style="align-items: end">
                                    <div class="name">韩妈妈</div>
                                    <div class="info">
                                        <span>49岁</span>
                                        <span>步入中老年</span>
                                    </div>
                                </div>
                            </div>
                            <img src="https://khome2.tuzuu.com/vita/atext_1.png" />
                        </div>
                    </van-swipe-item>
                    <van-swipe-item>
                        <div class="my-swipe-item">
                            <div class="flex" style="margin-bottom:25px">
                                <van-image round width="50px" height="50px" src="https://khome2.tuzuu.com/vita/preson_2.png"/>
                                <div class="flex-column-center person" style="align-items: end">
                                    <div class="name">晓晓妈</div>
                                    <div class="info">
                                        <span>29岁</span>
                                        <span>刚生完宝宝</span>
                                    </div>
                                </div>
                            </div>
                            <img src="https://khome2.tuzuu.com/vita/atext_2.png" />
                        </div>
                    </van-swipe-item>
                    <van-swipe-item>
                        <div class="my-swipe-item">
                            <div class="flex" style="margin-bottom:25px">
                                <van-image round width="50px" height="50px" src="https://khome2.tuzuu.com/vita/preson_3.png"/>
                                <div class="flex-column-center person" style="align-items: end">
                                    <div class="name">刘先生</div>
                                    <div class="info">
                                        <span>25岁</span>
                                        <span>步入职场2,3年</span>
                                    </div>
                                </div>
                            </div>
                            <img src="https://khome2.tuzuu.com/vita/atext_3.png" />
                        </div>
                    </van-swipe-item>
                </van-swipe>
            </div>
        </div>
        
        <img src="https://khome2.tuzuu.com/vita/chome_4.png?v=8" />
        <img src="https://khome2.tuzuu.com/vita/chome_5.png" />

        <div class="order-3">
            <img src="https://khome2.tuzuu.com/vita/home_17.png" style="border-radius: 10px"/>
        </div>

        <div class="bottom-btn flex-between">
            <div class="left">
                <p v-if="data.show_price">360天全方位健康管理: <s>¥{{data.show_price}}</s></p>
                <p style="font-size: 12px" v-if="data.month_price">
                    券后折合每月仅<strong>¥{{data.month_price}}</strong>
                </p>
            </div>
            <div class="right" @click="createOrder">

                <p v-if="data.coupon_price">立即购买</p>
                <p v-else>领劵购买</p>
                <p v-if="data.show_price">
                    券后<strong>¥{{data.price }}</strong>
                </p>
                <p v-else><strong>¥{{data.goods_price}}</strong></p>
            </div>
        </div>
    </div>
</template>

<script>
    import HomeDesc from "../../components/HomeDesc";
    import {weChatPay} from "../../wechat/weChatPay";
    export default {
        name: "",
        components: {
            HomeDesc
        },
        data(){
            return{
                data: {},
                sn:'',
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                let id = this.$route.params.id
                let adviser_id = this.$route.query.adviser_id
                let is_sub = this.$route.query.is_sub
                this.$request.get(
                    "/goods_detail", {
                        goods_id: id,
                        adviser_id:adviser_id || 0,
                        is_sub:is_sub || 0
                    }
                ).then(res => {
                    this.data = res.data
                    if(res.data.sn && res.data.is_addr == 1){
                        this.$router.push({
                            path:`/product/show/${res.data.sn}`
                        })
                    }else if(res.data.sn && res.data.is_addr == 0){
                        this.$router.push({
                            path:`/product/address/${res.data.sn}`
                        })
                    }
                })
            },
            // 支付
            createOrder() {
                let id = this.$route.params.id
                let adviser_id = this.$route.params.adviser_id
                let is_sub = this.$route.query.is_sub
                this.$request.post(
                    "create_order", {
                        goods_id: id,
                        adviser_id: adviser_id,
                        is_sub:parseInt(is_sub) || 0
                    }
                ).then(res => {
                    if (res.code === 1) {
                        this.sn = res.data.orderSn
                        weChatPay({
                            wxPayConfig: res.data.payConfig,
                            onSuccess: () => {
                                console.log('成功')
                                this.wxPaySuccess()
                            }
                        })
                    }
                })
            },
            //支付成功
            wxPaySuccess(){
                const toast = this.$toast.loading({
                    duration: 0,
                    forbidClick: true,
                    message: "请稍候...",
                })
                setTimeout(() => {
                    toast.type = "success";
                    toast.message = "支付完成";
                    setTimeout(() => {
                        toast.clear();
                        this.$router.push({
                            path: `/product/success/${this.sn}`
                        });
                    })
                },700)
            }
        }
    }
</script>

<style scoped lang="less">
    .order {
        width: 100%;
        background-color: #F3F3F4;
        padding-bottom: 77px;

        .order-1 {
            margin-top: 15px;
            background-color: #6D00AF;
            padding: 0px 10px;
            padding-bottom: 325px;
            position: relative;
        }

        .home-desc {
            width: calc(100% - 20px);
            background-color: white;
            position: absolute;
            bottom: 20px;
            left: 10px;
            border-radius: 10px;
            padding: 25px 20px;
            box-sizing: border-box;

            .title {
                font-size: 20px;
                text-align: center;
                color: #3F3F3F;
                margin-bottom: 25px;
                box-sizing: border-box;
                font-weight: 900;
                font-family: HYQiHei-GEW, HYQiHei;
            }
        }

        .order-2{
            margin:auto;
            margin-top:15px;
            margin-bottom: 20px;
            width:calc(100% - 20px);
            background-color: #6D00AF;
            padding: 20px 0px;
            box-sizing: border-box;
            border-radius: 10px;
            .title{
                color:white;
                font-size:20px;
                text-align:center;
                margin-bottom:20px;
                font-weight: 700;
            }
        }

        //轮播图
        .my-swipe {
            width: calc(100%);

            /deep/ .van-swipe {
                padding-bottom: 25px;
            }

            /deep/ .van-swipe__indicators {
                bottom: 0px !important;
            }
            .van-swipe-item {
                padding: 0px 10px;
                box-sizing: border-box;
                width: 100%;
            }
            .my-swipe-item {
                width: 100%;
                background-color: #FDFDFD;
                border-radius: 10px;
                padding: 20px 15px 20px 15px;
                box-sizing: border-box;
                height: 255px;
                //信息
                .person {
                    margin-left: 15px;

                    .name {
                        font-size: 15px;
                        color: #3F3F3F;
                        font-weight: 700;
                        margin-bottom: 6px;
                    }

                    .info {
                        font-size: 14px;
                        color: #666666;
                        font-weight: 700;
                        span{
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .order-3{
            padding: 15px 10px 25px 10px;
            background-color: #6D00AF;
        }
        .bottom-btn {
            position: fixed;
            width: 100%;
            background-color: white;
            height: 77px;
            left: 0;
            bottom: 0;
            padding: 0px 15px;
            box-sizing: border-box;
            border-top:1px solid #cfcfcf;
            z-index: 6989996;
            .left {
                font-size: 13px;
                color: #3F3F3F;
                padding: 8px 0px;
                box-sizing: border-box;
                font-weight: 700;
                line-height: 26px;

                strong {
                    font-size: 20px;
                    font-weight: 700;
                    margin-left: 5px;
                }
            }

            .right {
                padding: 7px 15px;
                background-color: #FE4B12;
                border-radius: 5px;
                font-size: 12px;
                color: white;
                text-align: center;
                white-space: nowrap;
                strong {
                    font-size: 20px;
                    font-weight: 700;
                    margin-left: 5px;
                }
            }
        }
    }
</style>